import { Layout } from "@app/layout/Layout";
import * as pages from "@pages";
import { PATHS } from "@utils/constants/constants";
import { createBrowserRouter } from "react-router-dom";

export const Router = createBrowserRouter([
  {
    element: <Layout />,
    errorElement: <pages.NotFound />,
    children: [
      {
        path: PATHS.home,
        element: <pages.HomePage />,
      },
      {
        path: PATHS.about,
        element: <pages.AboutPage />,
      },
      {
        path: PATHS.news,
        element: <pages.NewsPage />,
      },
      {
        path: PATHS.newsDetail,
        element: <pages.NewsDetailPage />,
      },
      {
        path: PATHS.academics.bachelor,
        element: <pages.BBAPage />,
      },
      {
        path: PATHS.academics.master,
        element: <pages.MBAPage />,
      },
      {
        path: PATHS.academics.partner_universities,
        element: <pages.PartnerPage />,
      },
      {
        path: PATHS.academics.accreditations,
        element: <pages.AccreditationPage />,
      },
      {
        path: PATHS.people.faculty_staff,
        element: <pages.FacultyStaffPage />,
      },
      {
        path: PATHS.people.alumni,
        element: <pages.AlumniPage />,
      },
      {
        path: PATHS.people.students,
        element: <pages.StudentsPage />,
      },
      {
        path: PATHS.research,
        element: <pages.ResearchPage />,
      },
      {
        path: PATHS.engagement,
        element: <pages.EngagementPage />,
      },
    ],
  },
]);
