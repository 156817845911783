import logo from "@assets/Images/LOGO.svg";
import { Link } from "react-router-dom";
import styles from "./LogoAUCA.module.scss";

export const LogoAUCA = ({ variant }) => {
  return (
    <Link className={`${styles.logoAUCA} ${styles[variant]}`} to="/">
      <img src={logo} alt="AUCA logo" />
    </Link>
  );
};
