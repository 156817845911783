import { BASE_URL } from "@utils/constants/constants";
import axios from "axios";

const createApi = () => {
  const api = axios.create({
    baseURL: BASE_URL,
  });

  return api;
};

const $mainApi = createApi();
export { $mainApi };
