import { ArrowButton, ButtonArrow } from "@assets/Icons";
import styles from "./Button.module.scss";

export const Button = (props) => {
  const {
    children,
    onClick,
    style,
    className = "",
    disabled = false,
    arrowRight = false,
    arrowButton,
    flip = false,
  } = props;

  const classNames = [
    styles[arrowButton ? "arrowButton" : "customButton"],
    className,
    disabled ? styles.disabled : "",
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <button
      className={classNames}
      onClick={onClick}
      style={style}
      disabled={disabled}
    >
      {children}
      {arrowRight && <ButtonArrow className={styles.arrow} />}
      {arrowButton && <ArrowButton flip={flip} variant={arrowButton} />}
    </button>
  );
};
