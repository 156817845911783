import classNames from "classnames";
import { forwardRef } from "react";
import { useController } from "react-hook-form";
import styles from "./InputField.module.scss";

export const InputField = forwardRef(
  ({ control, name, label, type, placeholder, error }, ref) => {
    const {
      field: { onChange, value },
    } = useController({ name, control });

    const inputValue = value ?? "";

    return (
      <div>
        {label && <label className={styles.label}>{label}</label>}
        <input
          ref={ref}
          type={type}
          name={name}
          label={label}
          value={inputValue}
          onChange={onChange}
          placeholder={placeholder}
          className={classNames(styles.field, styles.default, {
            [styles.errorField]: error,
          })}
        />
        {error && <span className={styles.error}>{error}</span>}
      </div>
    );
  },
);

InputField.displayName = "InputField";
