import { breadcrumbs, PATHS } from "@utils/constants/constants";

/**
 * debounce
 */
export const debounce = (fn, delay) => {
  let timeout;
  return function () {
    const fnCall = () => {
      fn.apply(this, arguments);
    };
    clearTimeout(timeout);
    timeout = setTimeout(fnCall, delay);
  };
};

/**
 * stop scrolling when modal is open
 */
export const toggleBodyScroll = (disable) => {
  if (disable) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "";
  }
};

/**
 * get correct route path
 */
export const generateRoutePath = (title) => {
  if (title === "Bachelor of Business Administration") {
    return "/bachelor-of-business-administration";
  } else if (title === "Master of Business Administration") {
    return "/master-of-business-administration";
  }
};

/**
 * filtered MBA & BBA data by order
 */
export const filteredContent = (contentType, allData) => {
  return allData?.filter((item) => item.content_type === contentType);
};

export const filterByTitle = (order, data) => {
  return data.find((item) => item.ordering === order) || null;
};

/**
 * truncate strings
 */
export const truncateString = (str, length) => {
  if (typeof str === "string") {
    return str.length <= length ? str : str.slice(0, length) + "...";
  }

  return str;
};

/**
 * breadcrumbs
 */
export const generateBreadcrumbs = (key, currentNews = null) => {
  const breadcrumbItem = breadcrumbs.find((item) => item.key === key);
  if (!breadcrumbItem) return [];

  const baseBreadcrumbs = [
    { text: "Home", route: PATHS.home, class: "breadCrumb" },
    {
      text: breadcrumbItem.label,
      route: currentNews ? PATHS.news : null,
      class: "breadCrumb",
    },
  ];

  if (currentNews) {
    baseBreadcrumbs.push({
      text: currentNews,
      route: null,
      class: "breadCrumbActive",
    });
  }

  return baseBreadcrumbs;
};
