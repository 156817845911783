import { $mainApi } from "@utils/axios/requester";
import { create } from "zustand";

export const useFooterData = create((set) => ({
  footerData: [],
  loading: false,
  error: null,

  fetchFooterData: async () => {
    set({ loading: true, error: null });
    try {
      const { data } = await $mainApi.get("/main-page/contact/");
      set({ footerData: data });
    } catch (error) {
      set({
        error: error.message || "Failed to fetch numbers",
        loading: false,
      });
    }
  },
}));
