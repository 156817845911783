import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { RouterProvider } from "react-router-dom";
import { Router } from "./router/Router";
import "./styles/global.scss";

const root = document.getElementById("root");

createRoot(root).render(
  <HelmetProvider>
    <RouterProvider router={Router} />
  </HelmetProvider>,
);
