export const BASE_URL = import.meta.env.VITE_APP_API_URL;

// === Routes ===
export const PATHS = {
  notFound: "*",
  home: "/",
  about: "/about",
  news: "/news",
  newsDetail: "/news/:id",
  academics: {
    ROOT: "/academics",
    bachelor: "/bachelor-of-business-administration",
    master: "/master-of-business-administration",
    partner_universities: "/partner-universities",
    accreditations: "/accreditations",
  },
  people: {
    ROOT: "/people",
    faculty_staff: "/faculty-and-staff",
    alumni: "/alumni",
    students: "/students",
  },
  research: "/research",
  engagement: "/engagement",
};
// for mobile nav
export const menuItems = [
  { label: "Home", link: PATHS.home },
  { label: "About Us", link: PATHS.about },
  { label: "News", link: PATHS.news },
  {
    label: "Academics",
    subItems: [
      {
        label: "Bachelor of Business Administration",
        link: PATHS.academics.bachelor,
      },
      {
        label: "Master of Business Administration",
        link: PATHS.academics.master,
      },
      {
        label: "Partner Universities",
        link: PATHS.academics.partner_universities,
      },
      { label: "Accreditations", link: PATHS.academics.accreditations },
    ],
  },
  {
    label: "People",
    subItems: [
      { label: "Faculty and Staff", link: PATHS.people.faculty_staff },
      { label: "Alumni", link: PATHS.people.alumni },
      { label: "Students", link: PATHS.people.students },
    ],
  },
  { label: "Research", link: PATHS.research },
  { label: "Engagement", link: PATHS.engagement },
];

// === footerLinks ===
export const FooterLinks = [
  { id: 1, label: "Students", link: PATHS.people.students },
  { id: 2, label: "Faculty and Staff", link: PATHS.people.faculty_staff },
  { id: 3, label: "Engagement", link: PATHS.engagement },
  { id: 4, label: "Research", link: PATHS.research },
];

// === filterOptions ===
export const filterOptions = [
  { id: 1, type: "latest", label: "First the new ones", ordering: "-date" },
  { id: 2, type: "oldest", label: "First the old ones", ordering: "date" },
  { id: 3, type: "aToZ", label: "From A to Z", ordering: "a" },
  { id: 4, type: "zToA", label: "From Z to A", ordering: "-a" },
];

// === breadcrumbs ===
export const breadcrumbs = [
  { key: "aboutUs", label: "About-Us" },
  { key: "news", label: "News" },
  { key: "bba", label: "Bachelor of Business Administration" },
  { key: "mba", label: "Master of Business Administration" },
  { key: "partner", label: "Partner Universities" },
  { key: "accreditation", label: "Accreditation" },
  { key: "staff", label: "Faculty and staff" },
  { key: "alumni", label: "Alumni" },
  { key: "students", label: "Students" },
  { key: "research", label: "Research" },
  { key: "engagement", label: "Engagement" },
];
