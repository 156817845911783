import { IconDown, IconTop } from "@assets/Icons";
import { truncateString } from "@utils/helpers/helpers";
import { useMediaQuery } from "@utils/hooks/useMediaQuery";
import { useOutsideClick } from "@utils/hooks/useOutsideClick";
import classNames from "classnames";
import { forwardRef, useRef, useState } from "react";
import styles from "./CustomSelect.module.scss";

export const CustomSelect = forwardRef(
  ({ options, placeholder = "Select an option", onChange, error }, ref) => {
    const isMobile = useMediaQuery("(max-width: 743px)");
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);
    const dropdownRef = useRef(null);

    useOutsideClick(dropdownRef, () => setIsOpen(false));
    const handleToggleDropdown = () => setIsOpen((prev) => !prev);

    const handleOptionClick = (option) => {
      setSelectedValue(option);
      setIsOpen(false);
      if (onChange) onChange(option);
    };

    return (
      <div ref={dropdownRef} className={styles.wrapper}>
        <label className={styles.label}>Direction</label>
        <div
          ref={ref}
          className={classNames(styles.input, {
            [styles.inputError]: error,
          })}
          onClick={handleToggleDropdown}
        >
          <span
            className={classNames({
              [styles.selectedValue]: selectedValue,
              [styles.placeholder]: !selectedValue,
            })}
          >
            {selectedValue
              ? truncateString(selectedValue, isMobile ? 30 : 55)
              : placeholder}
          </span>
          <span className={styles.arrow}>
            {isOpen ? <IconTop /> : <IconDown />}
          </span>
        </div>
        {isOpen && (
          <ul
            className={classNames(styles.dropdown, {
              [styles.dropdownOpen]: isOpen,
              [styles.dropdownClosed]: !isOpen,
            })}
          >
            {options?.map(({ id, title }) => (
              <li
                key={id}
                className={styles.option}
                onClick={() => handleOptionClick(title)}
              >
                {title}
              </li>
            ))}
          </ul>
        )}
        {error && <span className={styles.error}>{error}</span>}
      </div>
    );
  },
);

CustomSelect.displayName = "CustomSelect";
