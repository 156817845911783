import { IconGeeks } from "@assets/Icons";
import { Typography } from "@ui";
import styles from "./Copyright.module.scss";

export const Copyright = () => {
  return (
    <div className={styles.copyright}>
      <a
        href="https://geeks.kg/"
        target="_blank"
        rel="noreferrer"
        className={styles.copyrightLink}
      >
        <Typography className={styles.copyrightText}>Made by Geeks</Typography>
        <IconGeeks />
      </a>
    </div>
  );
};
