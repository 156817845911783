export const IconTop = ({ width = 24, height = 24, color = "#000000" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1974_3871)">
        <path
          d="M24.6152 24H0.615234V0H24.6152V24Z"
          fill="white"
          fillOpacity="0.01"
        />
        <path
          d="M19.1152 15L13.1152 9L7.11523 15"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1974_3871">
          <rect
            width={width}
            height={height}
            fill="white"
            transform="matrix(1 0 0 -1 0.615234 24)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
