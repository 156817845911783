import { Footer } from "@modules/Footer/";
import { Typography } from "@ui";
import { PATHS } from "@utils/constants/constants";
import { useNavigate } from "react-router-dom";
import style from "./NotFound.module.scss";
import { Header } from "@modules/Header/index.js";

export const NotFound = () => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(PATHS.home);
  };

  return (
    <>
      <Header />
      <div className={style.notFoundContainer}>
        <Typography
          variant="large"
          weight="regular"
          className={style.notFoundTitle}
        >
          404
        </Typography>
        <Typography
          variant="h1"
          weight="regular"
          className={style.notFoundSubtitle}
        >
          Something’s missing
        </Typography>
        <Typography
          variant="h3"
          weight="regular"
          className={style.notFoundDescription}
        >
          This page is missing or you assembled the link incorrectly
        </Typography>
        <button className={style.roundedButton} onClick={handleNavigation}>
          <Typography variant="body-l">Go to main</Typography>
        </button>
      </div>
      <Footer />
    </>
  );
};
