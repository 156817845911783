import { lazy } from "react";

export const AboutPage = lazy(() => import("./AboutPage/AboutPage"));
export const AccreditationPage = lazy(
  () => import("./AccreditationPage/AccreditationPage"),
);
export const AlumniPage = lazy(() => import("./AlumniPage/AlumniPage"));
export const BBAPage = lazy(() => import("./BBAPage/BBAPage"));
export const EngagementPage = lazy(
  () => import("./EngagementPage/EngagementPage"),
);
export const FacultyStaffPage = lazy(
  () => import("./FacultyStaffPage/FacultyStaffPage"),
);
export const HomePage = lazy(() => import("./HomePage/HomePage"));
export const MBAPage = lazy(() => import("./MBAPage/MBAPage"));
export const NewsDetailPage = lazy(
  () => import("./NewsDetailPage/NewsDetailPage"),
);
export const NewsPage = lazy(() => import("./NewsPage/NewsPage"));
export const PartnerPage = lazy(() => import("./PartnerPage/PartnerPage"));
export const ResearchPage = lazy(() => import("./ResearchPage/ResearchPage"));
export const StudentsPage = lazy(() => import("./StudentsPage/StudentsPage"));
export { NotFound } from "./NotFound/NotFound";
