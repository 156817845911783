export const ArrowButton = ({ color = "#000000", flip = false, variant }) => {
  const type = {
    slider: { width: 19, height: 38 },
    pagination: { width: 9, height: 17 },
  };
  const { width, height } = type[variant];

  return (
    <svg
      width={width}
      height={height}
      viewBox={"0 0 19 38"}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: flip ? "scale(-1, 1)" : "scale(1, 1)" }}
    >
      <path
        d="M17.25 37.0624C16.8542 37.0624 16.4583 36.9166 16.1458 36.6041L2.5625 23.0208C0.354167 20.8124 0.354167 17.1874 2.5625 14.9791L16.1458 1.39575C16.75 0.791585 17.75 0.791585 18.3542 1.39575C18.9583 1.99992 18.9583 2.99992 18.3542 3.60409L4.77083 17.1874C3.77083 18.1874 3.77083 19.8124 4.77083 20.8124L18.3542 34.3958C18.9583 34.9999 18.9583 35.9999 18.3542 36.6041C18.0417 36.8958 17.6458 37.0624 17.25 37.0624Z"
        fill={color}
      />
    </svg>
  );
};
