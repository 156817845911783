import { IconClose } from "@assets/Icons";
import { toggleBodyScroll } from "@utils/helpers/helpers";
import classNames from "classnames";
import { useEffect, useState } from "react";
import styles from "./CustomModal.module.scss";

export const CustomModal = ({
  isOpen,
  onClose,
  children,
  origin,
  className,
  location,
}) => {
  const [show, setShow] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setShow(true);
      setIsClosing(false);
      toggleBodyScroll(true);
    } else {
      setIsClosing(true);
      toggleBodyScroll(false);
      setTimeout(() => setShow(false), 300);
    }
  }, [isOpen]);

  if (!isOpen && !show) return null;

  const style = origin
    ? {
        transformOrigin: `${origin.x}px ${origin.y}px`,
      }
    : {};

  return (
    <div
      className={classNames(styles.overlay, {
        [styles.headerOverlay]: location === "header",
        [styles.entityOverlay]: location === "entity",
        [styles.entityResOverlay]: location === "entityRes",
        [styles.layoutOverlay]: location === "layout",
      })}
      onClick={onClose}
    >
      {["entity", "entityRes"].includes(location) && (
        <div className={styles.closeEntity}>
          <button className={styles.closeEntityBtn} onClick={onClose}>
            <IconClose />
          </button>
        </div>
      )}
      <div
        className={classNames(styles.modal, className, {
          [styles.modalClose]: isClosing,
          [styles.modalOpen]: !isClosing,
        })}
        style={style}
        onClick={(e) => e.stopPropagation()}
      >
        {["header", "layout"].includes(location) && (
          <button
            className={classNames({
              [styles.closeButtonLayout]: location === "layout",
              [styles.closeButton]: location === "header",
            })}
            onClick={onClose}
          >
            <IconClose />
          </button>
        )}
        <div>{children}</div>
      </div>
    </div>
  );
};
