import { IconDown } from "@assets/Icons";
import { Typography } from "@ui";
import { menuItems, PATHS } from "@utils/constants/constants";
import { useMediaQuery } from "@utils/hooks/useMediaQuery";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Dropdown } from "../Dropdown/Dropdown";
import styles from "./Nav.module.scss";

export const Nav = ({ isOpen, handleClose, modalClick }) => {
  const [openItem, setOpenItem] = useState(null);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [, forceUpdate] = useState(0); // Для ререндера
  const location = useLocation();
  const isMobileTablet = useMediaQuery("(max-width: 1024px)");

  // Убираем лишние классы перед добавлением нового
  useEffect(() => {
    document.querySelectorAll(`.${styles.active}`).forEach((el) => {
      el.classList.remove(styles.active);
    });

    const activeElement = document.querySelector(
      `a[href='${location.pathname}']`,
    );
    if (activeElement) {
      activeElement.parentElement.classList.add(styles.active);
    }
  }, [location.pathname]);

  // Условие для определения активного состояния
  const isActive = (path) => location.pathname.includes(path);

  const handleToggle = (dropdownId) => {
    setOpenDropdown(openDropdown === dropdownId ? null : dropdownId);
  };

  const closeDropdown = () => {
    setOpenDropdown(null);
  };

  const handleLinkClick = () => {
    if (isMobileTablet) {
      setOpenItem(null);
      handleClose();
    }
    setTimeout(() => forceUpdate((prev) => prev + 1), 0); // Safari фикс
  };

  return (
    <nav className={styles.nav}>
      <ul className={`${styles.nav_items} ${isOpen ? styles.open : ""}`}>
        <li className={isActive(PATHS.about) ? styles.active : ""}>
          <Link to={PATHS.about} onClick={handleLinkClick}>
            <Typography variant="body">About us</Typography>
          </Link>
        </li>
        <li className={isActive(PATHS.news) ? styles.active : ""}>
          <Link to={PATHS.news} onClick={handleLinkClick}>
            <Typography variant="body">News</Typography>
          </Link>
        </li>

        {!isMobileTablet
          ? ["Academics", "People"].map((label, idx) => (
              <Dropdown
                key={idx}
                title={label}
                items={menuItems.find((item) => item.label === label)}
                isOpen={openDropdown === label.toLowerCase()}
                onToggle={() => handleToggle(label.toLowerCase())}
                closeDropdown={closeDropdown}
              />
            ))
          : menuItems
              .filter(
                (item) => item.label === "Academics" || item.label === "People",
              )
              .map((item, index) => (
                <li
                  key={index}
                  className={styles.dropdown}
                  onClick={() => setOpenItem(openItem === index ? null : index)}
                >
                  <div className={styles.dropdownLabel}>
                    <div className={styles.ItemLabel}>
                      <Typography variant="body">{item.label}</Typography>
                    </div>
                    <div
                      className={`${styles.iconArrow} ${openItem === index ? styles.iconRotate : ""}`}
                    >
                      <IconDown />
                    </div>
                  </div>
                  <ul
                    className={`${styles.SubMenu} ${
                      openItem === index ? styles.SubMenuOpen : ""
                    }`}
                  >
                    {item.subItems.map((subItem, subIndex) => (
                      <li
                        key={subIndex}
                        className={isActive(subItem.link) ? styles.active : ""}
                      >
                        <Link to={subItem.link} onClick={handleLinkClick}>
                          <Typography variant="body">
                            {subItem.label}
                          </Typography>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}

        <li className={isActive(PATHS.research) ? styles.active : ""}>
          <Link to={PATHS.research} onClick={handleLinkClick}>
            <Typography variant="body">Research</Typography>
          </Link>
        </li>
        <li className={isActive(PATHS.engagement) ? styles.active : ""}>
          <Link to={PATHS.engagement} onClick={handleLinkClick}>
            <Typography variant="body">Engagement</Typography>
          </Link>
        </li>

        {isMobileTablet ? (
          <li onClick={modalClick} className={styles.burgerContactUs}>
            <Typography variant="body">Contact us</Typography>
          </li>
        ) : null}
      </ul>
    </nav>
  );
};
