import { CustomModal } from "@modules/CustomModal";
import { Typography } from "@ui";
import classNames from "classnames";
import styles from "./LayoutModal.module.scss";

export const LayoutModal = ({ isOpen, closeModal, isError, isSuccess }) => {
  return (
    <CustomModal
      isOpen={isOpen}
      onClose={closeModal}
      className={styles.modal}
      location="layout"
    >
      <div
        className={classNames({
          [styles.content]: isSuccess,
          [styles.errContent]: isError,
        })}
      >
        <div className={styles.title}>
          {isSuccess && (
            <Typography variant="h4" weight="bold" color="darkblue">
              Thank you!
            </Typography>
          )}
          <Typography
            variant="h4"
            weight="bold"
            color={isError ? "danger" : "darkblue"}
            className={styles.title}
          >
            {isError
              ? "Something went wrong!"
              : "Your request has been successfully submitted"}
          </Typography>
        </div>
        <Typography variant="body" color="darkblue">
          {isError ? "Try again" : "We will contact you shortly."}
        </Typography>
      </div>
    </CustomModal>
  );
};
