import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

export const useScrollToTop = (dependency) => {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    if ("scrollRestoration" in history) history.scrollRestoration = "manual";

    window.scroll({
      top: 0,
      left: 0,
    });

    return () => {
      if ("scrollRestoration" in history) history.scrollRestoration = "auto";
    };
  }, [pathname, dependency]);
};
