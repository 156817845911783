import logo from "@assets/Images/LOGO.svg";
import { ApplicationForm } from "@modules/ApplicationForm";
import { CustomModal } from "@modules/CustomModal";
import { useModalStore } from "@modules/CustomModal/model/useModalModel";
import { Button, LogoAUCA, MultiContainer } from "@ui";
import { useMediaQuery } from "@utils/hooks/useMediaQuery";
import { useOutsideClick } from "@utils/hooks/useOutsideClick";
import { useRef, useState } from "react";
import { BurgerMenu } from "../BurgerMenu/BurgerMenu";
import { Nav } from "../nav/Nav";
import styles from "./Header.module.scss";

export const Header = () => {
  const { isHeaderOpen, openHeaderModal, closeModal } = useModalStore();
  const [isOpen, setIsOpen] = useState(false);
  const [origin, setOrigin] = useState(null);
  const navRef = useRef(null);

  const handleCloseNav = () => setIsOpen(false);

  const toggleMenu = (event) => {
    event.stopPropagation();
    setIsOpen((prev) => !prev);
  };

  const handleButtonClick = (event) => {
    const rect = event.target.getBoundingClientRect();
    setOrigin({ x: rect.left + rect.width / 2, y: rect.top + rect.height / 2 });
    openHeaderModal();
  };

  const isMobileTablet = useMediaQuery("(max-width: 1024px)");

  useOutsideClick(navRef, () => {
    if (isOpen) setIsOpen(false);
  });

  return (
    <header className={styles.header}>
      <MultiContainer className={styles.container}>
        <div className={styles.header_inner}>
          <LogoAUCA variant="headerLogo" />
          {isMobileTablet ? (
            <div className={styles.burger} ref={navRef}>
              <Nav
                isOpen={isOpen}
                handleClose={handleCloseNav}
                modalClick={handleButtonClick}
              />
              <BurgerMenu isOpen={isOpen} toggleMenu={toggleMenu} />
            </div>
          ) : (
            <>
              <Nav />
              <Button onClick={handleButtonClick} className={styles.button}>
                Contact us
              </Button>
            </>
          )}
        </div>
        <CustomModal
          origin={origin}
          isOpen={isHeaderOpen}
          onClose={closeModal}
          className={styles.modal}
          location="header"
        >
          <div className={styles.content}>
            <div className={styles.imageWrap}>
              <img src={logo} alt="logo" className={styles.img} />
            </div>
            <ApplicationForm onClose={closeModal} />
          </div>
        </CustomModal>
      </MultiContainer>
    </header>
  );
};
