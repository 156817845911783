import { Typography } from "@ui";
import { FooterLinks } from "@utils/constants/constants";
import { NavLink } from "react-router-dom";
import styles from "./FooterNav.module.scss";

export const FooterNav = () => {
  return (
    <nav>
      <Typography variant="h4" weight="bold" className={styles.FooterNavTitle}>
        Learn More
      </Typography>
      <ul className={styles.FooterNavBlock}>
        {FooterLinks.map((item) => (
          <li key={item.id}>
            <NavLink to={item.link}>
              <Typography variant="body" color="gray" className={styles.link}>
                {item.label}
              </Typography>
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};
