import { Typography } from "@ui";
import styles from "./CopyrightAUCA.module.scss";

export const CopyrightAUCA = () => {
  return (
    <div className={styles.CopyrightAUCA}>
      <Typography variant="body">
        &copy; 1993 American University of central Asia
      </Typography>
    </div>
  );
};
