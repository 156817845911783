import { $mainApi } from "@utils/axios/requester";
import { create } from "zustand";

export const useDirections = create((set) => ({
  directions: [],
  loading: false,
  error: null,
  submitResult: null,
  submitLoading: false,
  submitError: null,

  fetchDirections: async () => {
    set({ loading: true, error: null });
    try {
      const response = await $mainApi.get("/main-page/contact-form/direction");
      set({ directions: response.data });
    } catch (error) {
      set({ error: error.message || "Failed to fetch directions" });
    } finally {
      set({ loading: false });
    }
  },

  submitApplication: async (data, openLayoutModal) => {
    set({ submitLoading: true, submitError: null });
    try {
      const response = await $mainApi.post("/main-page/contact/appeal/", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        set({ submitResult: response.data, submitLoading: false });
      }
      openLayoutModal();
    } catch (error) {
      set({
        submitError: error.message || "Failed to fetch directions",
        submitLoading: false,
      });
      openLayoutModal();
    }
  },
}));
