import arrow from "@assets/Images/arrow.svg";
import { Typography } from "@ui/Typography/Typography";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import styles from "./Dropdown.module.scss";

export const Dropdown = ({ title, items, isOpen, onToggle, closeDropdown }) => {
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    if (isOpen) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen, closeDropdown]);

  return (
    <li ref={dropdownRef} className={styles.dropdown}>
      <div onClick={onToggle} className={styles.dropdownToggle}>
        <Typography variant="body" className={styles.title}>
          {title}
        </Typography>
        <img
          src={arrow}
          alt="arrow"
          className={isOpen ? styles.arrowOpen : ""}
        />
      </div>
      <ul className={`${styles.dropdown_menu} ${isOpen ? styles.open : ""}`}>
        {items.subItems?.map((item, index) => (
          <li key={index} onClick={closeDropdown}>
            <Link to={item.link}>
              <Typography variant="smallBody">{item.label}</Typography>
            </Link>
          </li>
        ))}
      </ul>
    </li>
  );
};
