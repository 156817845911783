import { useModalStore } from "@modules/CustomModal/model/useModalModel";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDirections } from "../../api/applicationRequest";
import { CustomSelect } from "../CustomSelect/CustomSelect";
import { InputField } from "../InputField/InputField";
import { SubmitButton } from "../SubmitButton/SubmitButton";
import styles from "./ApplicationForm.module.scss";

export const ApplicationForm = ({ onClose }) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const { fetchDirections, submitApplication, directions } = useDirections();
  const { openLayoutModal } = useModalStore();

  useEffect(() => {
    fetchDirections();
  }, [fetchDirections]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const onSubmit = async (data) => {
    try {
      onClose();
      await submitApplication(data, openLayoutModal);
      reset();
    } catch (error) {
      alert(error.message);
    }
  };

  const handleButtonClick = () => scrollToTop();

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <Controller
        name="name"
        control={control}
        rules={{
          required: "Name is required",
          pattern: {
            value: /^[а-яА-Яa-zA-Z\s]*$/,
            message: "Name can only contain letters",
          },
        }}
        render={({ field }) => (
          <InputField
            {...field}
            type="text"
            label="Name"
            name="name"
            control={control}
            placeholder="Enter your name"
            error={errors.name?.message}
          />
        )}
      />
      <Controller
        name="email"
        control={control}
        rules={{
          required: "Email is required",
          pattern: {
            value: /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}/,
            message: "Invalid email address",
          },
        }}
        render={({ field }) => (
          <InputField
            {...field}
            type="email"
            label="Email"
            name={"email"}
            control={control}
            placeholder="Email"
            error={errors.email?.message}
          />
        )}
      />
      <Controller
        name="direction"
        control={control}
        rules={{ required: "Direction is required" }}
        render={({ field }) => (
          <CustomSelect
            {...field}
            options={directions}
            placeholder="Choose an option"
            onChange={(value) => field.onChange(value)}
            error={errors.direction?.message}
          />
        )}
      />
      <Controller
        name="message"
        control={control}
        rules={{ required: "Message is required" }}
        render={({ field }) => (
          <InputField
            {...field}
            type="text"
            name={"message"}
            label="Message"
            control={control}
            placeholder="Type your message here..."
            error={errors.message?.message}
          />
        )}
      />
      <SubmitButton onClick={handleButtonClick} />
    </form>
  );
};
