import { useSearchParams } from "react-router-dom";

export const useModalStore = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const modal = searchParams.get("modal");

  const openModal = (name) => setSearchParams({ modal: name });
  const closeModal = () => setSearchParams({});

  return {
    isHeaderOpen: modal === "contact",
    isEntityOpen: modal === "entity",
    isLayoutOpen: modal === "layout",
    openHeaderModal: () => openModal("contact"),
    openEntityModal: () => openModal("entity"),
    openLayoutModal: () => openModal("layout"),
    closeModal,
  };
};
