import { useFooterData } from "@modules/Footer/api/useFooterLinks.js";
import { LogoAUCA, MultiContainer } from "@ui";
import { useEffect } from "react";
import { Contacts } from "../Contacts/Contacts.jsx";
import { Copyright } from "../Copyright/Copyright.jsx";
import { CopyrightAUCA } from "../CopyrightAUCA/CopyrightAUCA.jsx";
import { FooterNav } from "../FooterNav/FooterNav.jsx";
import { Socials } from "../Socials/Socials.jsx";
import styles from "./Footer.module.scss";

export const Footer = () => {
  const { fetchFooterData, footerData } = useFooterData();

  useEffect(() => {
    fetchFooterData();
  }, [fetchFooterData]);

  return (
    <footer className={styles.footer}>
      <MultiContainer>
        <div className={styles.footerContent}>
          <LogoAUCA variant="footerLogo" />
          <FooterNav />
          <Contacts data={footerData} />
          <Socials socials={footerData[0]?.social} />
        </div>
        <CopyrightAUCA />
      </MultiContainer>
      <Copyright />
    </footer>
  );
};
