import { truncateString } from "@utils/helpers/helpers";
import parse from "html-react-parser";
import styles from "./Typography.module.scss";

export const Typography = (props) => {
  const {
    variant,
    weight,
    children,
    className,
    color,
    truncate,
    id,
    style,
    lineHeight = false,
    allowHTML = false,
  } = props;

  const Tags = {
    large: "h1",
    h1: "h1",
    h2: "h2",
    h3: "h3",
    h4: "h4",
    h5: "h5",
    h6: "h6",
    button: "button",
    body: allowHTML ? "div" : "p",
    smallBody: "p",
    extraSmallBody: "p",
    span: "span",
  };

  const classNamedGenerated = [
    styles[variant],
    styles[weight],
    styles[color],
    styles[lineHeight],
    className,
  ]
    .join(" ")
    .trim();

  const TagName = Tags[variant] || "p";
  const WrapperTag = allowHTML ? "div" : TagName;

  if (allowHTML) {
    const content = parse(String(children));

    return (
      <WrapperTag style={style} id={id} className={classNamedGenerated}>
        {truncate ? truncateString(content, truncate) : content}
      </WrapperTag>
    );
  }

  return (
    <WrapperTag style={style} id={id} className={classNamedGenerated}>
      {truncate ? truncateString(children, truncate) : children}
    </WrapperTag>
  );
};
