import { Typography } from "@ui";
import classNames from "classnames";
import { Link } from "react-router-dom";
import styles from "./Contacts.module.scss";

export const Contacts = ({ data }) => {
  return (
    <div>
      <Typography variant="h4" weight="bold" className={styles.contactsTitle}>
        Contacts
      </Typography>
      <ul className={styles.contactsBlock}>
        <li className={styles.contactsInfo}>
          <Typography variant="body" color="gray">
            Email:&nbsp;
          </Typography>
          <Link to={`mailto:${data?.email}`}>
            <Typography variant="body" color="gray">
              {data[0]?.email}
            </Typography>
          </Link>
        </li>
        <li className={styles.contactsInfo}>
          <Typography variant="body" color="gray">
            Telephone:&nbsp;
          </Typography>
          <Link to={`tel:${data?.telephone}`}>
            <Typography variant="body" color="gray">
              {data[0]?.telephone}
            </Typography>
          </Link>
        </li>
        <li className={classNames(styles.contactsInfo, styles.contactsWidth)}>
          <Typography variant="body" color="gray">
            Campus Address:&nbsp;
            <a
              href={data[0]?.address[0]?.title}
              target="_blank"
              className={styles.link}
            >
              {data[0]?.address[0]?.title}
            </a>
          </Typography>
        </li>
      </ul>
    </div>
  );
};
