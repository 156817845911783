export const IconScrollUp = ({
  width = 34,
  height = 18,
  color = "#ffffff",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 34 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.6169 15.9225C33.6169 16.2867 33.4828 16.6508 33.1953 16.9383C32.6394 17.4942 31.7194 17.4942 31.1636 16.9383L18.6669 4.44168C17.7469 3.52168 16.2519 3.52168 15.3319 4.44168L2.83526 16.9383C2.27943 17.4942 1.35943 17.4942 0.803594 16.9383C0.24776 16.3825 0.24776 15.4625 0.803594 14.9067L13.3003 2.41001C14.2778 1.43251 15.6003 0.876681 16.9994 0.876681C18.3986 0.876681 19.7211 1.41335 20.6986 2.41001L33.1953 14.9067C33.4636 15.1942 33.6169 15.5583 33.6169 15.9225Z"
        fill={color}
      />
    </svg>
  );
};
