import { LayoutModal } from "@components/LayoutModal/LayoutModal";
import { useDirections } from "@modules/ApplicationForm/api/applicationRequest";
import { useModalStore } from "@modules/CustomModal/model/useModalModel";
import { Footer } from "@modules/Footer";
import { Header } from "@modules/Header";
import { ErrorFallback, MultiContainer, ScrollUp, Spin } from "@ui";
import { useScrollToTop } from "@utils/hooks/useScrollToTop";
import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Outlet } from "react-router-dom";

export const Layout = () => {
  const { submitLoading, submitResult, submitError } = useDirections();
  const { isLayoutOpen, closeModal } = useModalStore();

  useScrollToTop();

  return (
    <>
      <Header />
      <main>
        <Suspense
          fallback={
            <MultiContainer className="loading">
              <Spin />
            </MultiContainer>
          }
        >
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            {submitLoading ? (
              <MultiContainer className="loading">
                <Spin />
              </MultiContainer>
            ) : (
              <>
                <Outlet />
                <ScrollUp />
              </>
            )}
          </ErrorBoundary>
        </Suspense>
      </main>
      <Footer />
      <LayoutModal
        isOpen={isLayoutOpen}
        isError={submitError}
        isSuccess={submitResult}
        closeModal={closeModal}
      />
    </>
  );
};
