import styles from "./BurgerMenu.module.scss";

export const BurgerMenu = ({ isOpen, toggleMenu }) => {
  return (
    <div className={styles.burger_menu}>
      <button
        className={`${styles.burger_icon} ${isOpen ? styles.open : ""}`}
        onClick={toggleMenu}
      >
        <span />
        <span />
        <span />
      </button>
    </div>
  );
};
