import { MultiContainer } from "@ui";
import styles from "./ErrorFallback.module.scss";

export const ErrorFallback = () => {
  return (
    <MultiContainer className="error-container">
      <div className={styles.wrapper}>
        <h2 className={styles.title}>Something went wrong</h2>
        <p className={styles.desc}>
          Data could not be loaded. Check your internet connection.
        </p>
      </div>
    </MultiContainer>
  );
};
