import { IconScrollUp } from "@assets/Icons";
import { MultiContainer } from "@ui/MultiContainer/MultiContainer.jsx";
import classNames from "classnames";
import { useEffect, useState } from "react";
import styles from "./ScrollUp.module.scss";

export const ScrollUp = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      const scrollQuarter =
        (document.documentElement.scrollHeight - window.innerHeight) * 0.25;
      if (window.scrollY > scrollQuarter) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const handleScrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <MultiContainer className={styles.scrollUp}>
      <button
        className={classNames(styles.scrollUpBtn, {
          [styles.visible]: isVisible,
          [styles.hidden]: !isVisible,
        })}
        onClick={handleScrollUp}
      >
        <IconScrollUp />
      </button>
    </MultiContainer>
  );
};
