import { Typography } from "@ui";
import styles from "./Socials.module.scss";

export const Socials = ({ socials }) => {
  return (
    <div className={styles.socials}>
      <Typography variant="h4" weight="bold" className={styles.socialsTitle}>
        Socials
      </Typography>
      <div className={styles.socialsLinks}>
        {socials?.map((item) => (
          <a key={item.id} href={item.link} target="_blank" rel="noreferrer">
            <div
              className={styles.socialsIcon}
              style={{ backgroundImage: `url(${item.icon})` }}
            ></div>
          </a>
        ))}
      </div>
    </div>
  );
};
